import { Injectable } from '@angular/core';
import { BaseHttp } from '@core/http/base.http';
import { environment } from '@environments/environment';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, take, tap } from 'rxjs/operators';
import { AuthState } from './state/auth.model';
import { AuthQuery } from './state/auth.query';
import { AuthStore } from './state/auth.store';
import { SelectHubsHelperService } from '@modules/select-hubs/services/select-hubs-helper.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseURL = environment.authBaseURL + '/account'; //identity server url

  constructor(
    private http: BaseHttp,
    private authStore: AuthStore,
    public authQuery: AuthQuery,
    private selectHubsHelperService: SelectHubsHelperService
  ) {}

  async isUserAuthenticated(): Promise<boolean> {
    return await this.authQuery.isLoggedIn$.pipe(take(1)).toPromise();
  }

  login(userName: string, enteredCode: string): Observable<AuthState> {
    const options = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    };
    const data =
      'grant_type=user_login_token&client_id=' +
      environment.clientId +
      '&scope=mf_public mf_users offline_access openid roles' +
      '&client_secret=' +
      environment.clientSecret +
      '&' +
      'user_name=' +
      userName +
      '&verification_token=' +
      enteredCode;
    const url = this.baseURL + '/connect/token';
    return this.http.post<AuthState>(url, data, options).pipe(
      tap((userTokens: AuthState) => {
        this.authStore.update(userTokens);
      })
    );
  }

  verifyUser<VerifyPhone>(
    username: string,
    password: string,
    resendToken = null
  ): Observable<VerifyPhone> {
    const url = this.baseURL + '/api/verify_user_login';
    return this.http.post<VerifyPhone>(url, {
      userName: username,
      password: password,
      resendToken: resendToken
    });
  }

  async getAuthToken(): Promise<AuthState> {
    return await this.authQuery.select().pipe(take(1)).toPromise();
  }

  async refreshToken(): Promise<unknown> {
    const authState = await this.getAuthToken();
    const url = this.baseURL + '/connect/token';
    const data =
      'grant_type=refresh_token&client_id=' +
      environment.clientId +
      '&client_secret=' +
      environment.clientSecret +
      '&refresh_token=' +
      authState.refresh_token +
      '&scope=' +
      authState.scope;
    const options = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    };

    return this.http
      .post(url, data, options)
      .pipe(
        tap((userTokens: AuthState) => {
          this.authStore.update(userTokens);
        }),
        catchError((error) => {
          this.logout();
          return throwError(error);
        })
      )
      .toPromise();
  }

  logout(): void {
    this.selectHubsHelperService.clearHubData();
    this.logoutFromAPI();
    this.authQuery.resetData();
  }

  async logoutFromAPI(): Promise<unknown> {
    const authState = await this.getAuthToken();
    const url = this.baseURL + '/connect/revocation';
    const data =
      'token_type_hint=access_token&client_id=' +
      environment.clientId +
      '&client_secret=' +
      environment.clientSecret +
      '&token=' +
      authState.access_token;
    const options = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    };
    return this.http.post(url, data, options).toPromise();
  }
}
