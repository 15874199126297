import { Injectable } from '@angular/core';
import { Hub } from './interfaces/hubs.interface';

@Injectable({ providedIn: 'root' })
export class SelectHubsHelperService {
  hubIdStorageKey = 'hubId';
  hubNameStorageKey = 'hubName';

  get hubId(): number {
    return +localStorage.getItem(this.hubIdStorageKey);
  }

  get hubName(): string {
    return localStorage.getItem(this.hubNameStorageKey);
  }

  get hasHubId(): boolean {
    return (
      !!localStorage.getItem(this.hubIdStorageKey) &&
      !!localStorage.getItem(this.hubNameStorageKey)
    );
  }

  handleConfirmHub(hub: Hub): void {
    localStorage.setItem(this.hubIdStorageKey, hub.id.toString());
    localStorage.setItem(this.hubNameStorageKey, hub.mainAddressAlt);
  }

  clearHubData(): void {
    localStorage.removeItem(this.hubIdStorageKey);
    localStorage.removeItem(this.hubNameStorageKey);
  }
}
